vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Presoterapia</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="presoterapia" src="../../assets/presoterapia.svg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content>
                        <p>
                            <span class="p-text-bold">Presoterapia: ¿qué es y para qué sirve?</span><br>
                            La presoterapia es un tratamiento para el drenaje y reducción de los edemas venosos y
                            linfáticos. Trata las alteraciones de piernas cansadas, arañitas vasculares, edemas, celulitis y
                            acumulaciones de grasa. Con esta técnica, se logra la sensación de un masaje con efectos de
                            relajación y compresión suficientes para activar el sistema linfático y, con ello, la
                            eliminación de líquidos, toxinas, edemas y varices.
                        </p>

                        <p>
                            <span class="p-text-bold">¿Cuánto dura una sesión de presoterapia?</span><br>
                            Cada sesión de presoterapia dura entre 30 minutos, y la cantidad de sesiones dependerá de los
                            problemas médicos de cada paciente y los objetivos que se quieren lograr. En este sentido, las
                            máquinas de presoterapia están dotadas de programas personalizados que se adaptan a las
                            necesidades y morfología de cada paciente.
                        </p>

                        <p>
                            <span class="p-text-bold">Beneficios de la presoterapia</span><br>
                            - Tratar la retención de líquidos.<br>
                            - Estimular el sistema circulatorio.<br>
                            - Eliminar y reducir la celulitis.<br>
                            - Eliminar toxinas y grasas del cuerpo.<br>
                            - Moldear tu silueta.
                        </p>

                        <p>
                            <span class="p-text-bold">Resultados de la presoterapia: antes y después</span><br>
                            La presoterapia se lleva a cabo a través de varias sesiones con una duración de entre 30 y 45
                            minutos. Estas, aunque dependan de las necesidades y el cuadro del paciente, se realizan
                            habitualmente dos veces por semana y durante todo un mes. Al final de cada sesión de
                            presoterapia, experimentarás resultados inmediatos en términos de relajación, demostrando así la
                            utilidad de la técnica desde el primer momento.
                        </p>

                        <p>
                            No es recomendable durante el embarazo, ni en caso de trombosis, ni si tenemos tensión baja.
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'PresoterapiaVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>